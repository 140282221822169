<template>
  <main class="ui">
    <h1 class="hidden">Cat Genius</h1>

    <!-- UI Logo -->
    <transition name="fade">
      <a
        href="/"
        :class="`logo__wrapper--home ui__element ui__element--top-left ${
          state !== $uiState.INIT && state !== $uiState.FINISHED
            ? 'in-game'
            : ''
        }`"
        key="white"
        v-if="state === $uiState.PLAYING || isLandscape || isWrongResolution"
      >
        <img
          src="/assets/logo--ol-white.png"
          alt="Cat Genius logo"
          class="logo--ol"
        />
      </a>
      <a
        href="/"
        :class="`logo__wrapper--home ui__element ui__element--top-left ${
          state !== $uiState.INIT && state !== $uiState.FINISHED
            ? 'in-game'
            : ''
        }`"
        key="black"
        v-else
      >
        <img
          src="/assets/logo--ol-black.png"
          alt="Cat Genius logo"
          class="logo--ol"
        />
      </a>
    </transition>

    <!-- UI Actions -->
    <div class="ui__actions ui__element ui__element--top-right">
      <IconButton
        @click.native="toggleSound"
        :scale="true"
        :icon="soundOn ? 'sound--on' : 'sound--off'"
        :color="
          $store.state.currentState === $uiState.PLAYING ||
          isLandscape ||
          isWrongResolution
            ? 'white'
            : 'black'
        "
      ></IconButton>
    </div>

    <!-- Screen check -->
    <DimensionsScreen
      v-if="isLandscape || isWrongResolution"
      :isWrongResolution="isWrongResolution"
      :isLandscape="isLandscape"
      key="intro"
    />

    <!-- Whack-A-Koi interfaces -->
    <transition-group name="slide-down">
      <div
        v-if="state === $uiState.PLAYING && gameState === $gameState.FISHING"
        key="timer"
        class="ui__koi ui__koi__timer ui__element ui__element--top-center timer__wrapper"
      >
        <p id="timer">
          {{ timer }}
        </p>
        <Button
          @click.native="handleSkipClick()"
          class="button--outline skip button--small"
        >
          {{ $trans("koiSkip") }}
        </Button>
      </div>

      <BarProgress
        key="progress"
        class="ui__element ui__element--top-center"
        v-if="
          state === $uiState.PLAYING &&
          gameState !== $gameState.FISHING_INTRO &&
          gameState !== $gameState.FISHING &&
          gameState !== $gameState.FISHING_END &&
          $store.state.questionAnswers.length > 0
        "
      />
      <div
        v-if="
          (state === $uiState.PLAYING && gameState === $gameState.FISHING) ||
          (state === $uiState.PLAYING &&
            gameState !== $gameState.FISHING_INTRO &&
            gameState !== $gameState.FISHING &&
            gameState !== $gameState.FISHING_END &&
            $store.state.questionAnswers.length > 0)
        "
        class="ui__koi__overlay"
        key="overlay"
      ></div>
    </transition-group>
    <transition name="slide-bottom">
      <div
        v-if="state === $uiState.PLAYING && gameState === $gameState.FISHING"
        class="ui__koi ui__koi__score ui__element ui__element--koi-score"
      >
        <p>{{ currentKoiScore }}</p>
        <div class="img__wrapper">
          <img
            src="/assets/img/koi--score.png"
            alt="Koi fish indication"
            id="fish"
          />
        </div>
      </div>
    </transition>
    <span
      id="hit"
      v-if="state === $uiState.PLAYING && gameState === $gameState.FISHING"
      class="ui__element ui__element--center"
    >
      +1
    </span>

    <!-- In-game Prompts -->
    <transition-group name="fade">
      <IntroPrompt
        v-if="state === $uiState.PLAYING && gameState === $gameState.INTRO"
        @allow-interaction="$emit('allow-interaction')"
        key="intro"
      />
      <KoiPrompt
        v-if="
          state === $uiState.PLAYING && gameState === $gameState.FISHING_INTRO
        "
        @count-down="showOverlay = false"
        @start-whackakoi="
          startTimer();
          $emit('start-whackakoi');
          showOverlay = true;
        "
        @skip-whackakoi="$emit('skip-whackakoi')"
        key="koi"
      />
      <TimesUpPrompt
        v-if="
          state === $uiState.PLAYING && gameState === $gameState.FISHING_END
        "
        @finish-whackakoi="$emit('finish-whackakoi')"
        key="timesup"
      />
      <div
        class="ui__koi ui__koi__overlay--multiply-blue"
        key="overlay"
        v-if="
          (state === $uiState.PLAYING &&
            gameState === $gameState.FISHING_END) ||
          (state === $uiState.PLAYING &&
            gameState === $gameState.FISHING_INTRO &&
            showOverlay)
        "
      ></div>
      <EndPrompt
        v-if="
          state === $uiState.PLAYING && gameState === $gameState.FINISHED_INTRO
        "
        @finish-game="finishGame()"
        key="end"
      />
    </transition-group>

    <!-- UI Screens -->
    <transition-group name="fade" v-if="!isLandscape">
      <IntroScreen
        v-if="state === $uiState.INIT && gameState === $gameState.PAUSED"
        @start-loading="startLoading()"
        key="intro"
      />
      <LoadingScreen
        v-if="state === $uiState.LOADING && gameState === $gameState.PAUSED"
        :progress="currentLoadPrecentage"
        @finish-loading="finishLoading()"
        key="loading"
      />
      <QuizScreen
        v-if="
          state === $uiState.QUIZ &&
          currentQuestion &&
          gameState === $gameState.PAUSED
        "
        :index="currentQuestion"
        @finish-quiz="$emit('finish-quiz', $event)"
        key="quiz"
      />
      <FinishedScreen
        v-if="state === $uiState.FINISHED && gameState === $gameState.FINISHED"
        key="finished"
      />
    </transition-group>
  </main>
</template>

<script>
import {
  DimensionsScreen,
  IntroScreen,
  LoadingScreen,
  QuizScreen,
  FinishedScreen,
} from "@/screens";
import {
  IntroPrompt,
  KoiPrompt,
  TimesUpPrompt,
  EndPrompt,
} from "@/components/prompts";
import { isTouchDevice, mountBlurListeners } from "@/helpers";
import { Sounds } from "@/constants";
import * as SoundController from "@/helpers/sounds";
import IconButton from "@/components/buttons/IconButton";
import BarProgress from "@/components/questions/BarProgress";
import Button from "@/components/buttons/Button";

export default {
  name: "UserInterface",
  components: {
    IconButton,
    Button,
    BarProgress,
    IntroScreen,
    LoadingScreen,
    QuizScreen,
    FinishedScreen,
    IntroPrompt,
    KoiPrompt,
    TimesUpPrompt,
    EndPrompt,
    DimensionsScreen,
  },
  props: ["state"],
  data() {
    return {
      currentLoadPrecentage: 0,
      currentQuestion: null,
      currentKoiScore: 0,
      countdown: 30000, // 30s
      timerInterval: null,
      showOverlay: true,
      isLandscape: this.getLandscape(),
      isWrongResolution: this.getResolution(),
    };
  },
  mounted() {
    this.mountListeners();
    mountBlurListeners(
      () => {
        // Blur
        window.soundState = false;
        this.muteSounds();
      },
      () => {
        // Focus
        if (this.soundOn) {
          window.soundState = true;
          this.unmuteSounds();
        }
      }
    );
    window.onorientationchange = () => {
      this.isLandscape = this.getLandscape();
      this.isWrongResolution = this.getResolution();
    };
    window.onresizeVueFunction = () => {
      this.isLandscape = this.getLandscape();
      this.isWrongResolution = this.getResolution();
    };
  },
  computed: {
    gameState() {
      return this.$store.state.currentGameState;
    },
    soundOn() {
      return this.$store.state.soundState;
    },
    timer() {
      return `${new Date(this.countdown).getMinutes()}:${
        new Date(this.countdown).getSeconds() === 0
          ? "00"
          : new Date(this.countdown).getSeconds() < 10
          ? `0${new Date(this.countdown).getSeconds()}`
          : new Date(this.countdown).getSeconds()
      }`;
    },
  },
  methods: {
    isTouchDevice: isTouchDevice,
    getResolution() {
      return false
      // if (!isTouchDevice()) {
      //   return window.innerHeight < 700 || window.innerWidth < 1024;
      // } else return false;
    },
    getLandscape() {
      if (isTouchDevice()) {
        if (
          screen.orientation &&
          screen.orientation.type &&
          screen.orientation.type.includes("landscape")
        ) {
          return true;
        } else if (
          window.innerHeight / window.innerWidth < 0.6 &&
          window.innerHeight < 700
        ) {
          return true;
        }
      }
      return false;
    },
    mountListeners() {
      document.addEventListener("loading-world", this.loadingWorld);
      document.addEventListener("open-quiz", this.openQuiz);
      document.addEventListener("intro-whackakoi", this.startWhackAKoi);
      document.addEventListener("score-whackakoi", this.hitAKoi);
      document.addEventListener("reached-end", this.reachEnd);
    },
    startLoading() {
      window.soundState = true;
      this.$store.state.soundState = true;
      this.$store.state.currentState = this.$uiState.LOADING;
      this.$store.state.currentGameState = this.$gameState.PAUSED;
      this.$emit("start-loading");

      // If mobile, go to fullscreen
      // if (isTouchDevice()) {
      //   let app = document.querySelector("#app");
      //   app.requestFullscreen();
      // }
    },
    loadingWorld(event) {
      this.currentLoadPrecentage = event.detail.progress;
    },
    finishLoading() {
      this.$store.state.currentState = this.$uiState.PLAYING;
      this.$store.state.currentGameState = this.$gameState.INTRO;
      document.removeEventListener("loading-world", this.loadingWorld);
      this.$emit("finish-loading");
    },
    openQuiz(event) {
      SoundController.play("openQuizSound");
      this.currentQuestion = event.detail.number;
      this.$store.state.currentState = this.$uiState.QUIZ;
      this.$store.state.currentGameState = this.$gameState.PAUSED;
    },
    startWhackAKoi() {
      this.$store.state.currentState = this.$uiState.PLAYING;
      this.$store.state.currentGameState = this.$gameState.FISHING_INTRO;
      document.removeEventListener("intro-whackakoi", this.startWhackAKoi);
    },
    hitAKoi() {
      SoundController.play("hitKoiSound");
      let hitPoint = document.getElementById("hit");
      let fish = document.getElementById("fish");
      hitPoint.classList.add("hit--animate");
      fish.classList.add("img--animate");

      setTimeout(() => {
        hitPoint.classList.remove("hit--animate");
        fish.classList.remove("img--animate");
        this.currentKoiScore++;
        this.$store.state.koisWhacked = this.currentKoiScore;
      }, 500);
    },
    reachEnd() {
      this.$store.state.currentState = this.$uiState.PLAYING;
      this.$store.state.currentGameState = this.$gameState.FINISHED_INTRO;
      document.removeEventListener("reached-end", this.startWhackAKoi);
    },
    toggleSound() {
      if (this.soundOn) {
        this.muteSounds();
        window.soundState = !window.soundState;
        this.$store.state.soundState = !this.$store.state.soundState;
      } else {
        window.soundState = !window.soundState;
        this.$store.state.soundState = !this.$store.state.soundState;
        this.unmuteSounds();
      }
    },
    muteSounds() {
      Sounds.forEach((sound) => {
        if (this.soundOn) {
          sound.src.fade(sound.src.volume(), 0, 300, sound.id);
        }
      });
    },
    unmuteSounds() {
      if (this.soundOn) {
        Sounds.forEach((sound) => {
          sound.src.fade(0, sound.initVolume, 300, sound.id);
        });
      }
    },
    startTimer() {
      if (this.countdown === 30000) {
        SoundController.play("clockTickSound");
        this.timerInterval = setInterval(() => {
          this.countdown -= 1000;
          if (this.countdown < 10000) {
            SoundController.increaseTo("clockTickSound", {
              rate: 0.8,
              volume: 1,
            });
            document.getElementById("timer").classList.add("pulsing");
          }
          if (this.countdown === 0) {
            // Game has finished
            this.finilizeWhackAKoi();
          }
        }, 1000);
      }
    },
    handleSkipClick() {
      // Round off the game
      this.finilizeWhackAKoi();
    },
    finilizeWhackAKoi() {
      SoundController.fadeOutStop("clockTickSound", 300);
      this.$emit("end-whackakoi");
      this.$store.state.koisWhacked = this.currentKoiScore;
      setTimeout(() => {
        this.$store.state.currentState = this.$uiState.PLAYING;
        this.$store.state.currentGameState = this.$gameState.FISHING_END;
        document.removeEventListener("score-whackakoi", this.hitAKoi);
      }, 1000);
      clearInterval(this.timerInterval);
    },
    finishGame() {
      this.$store.state.currentState = this.$uiState.FINISHED;
      this.$store.state.currentGameState = this.$gameState.FINISHED;
      this.$emit("finish-game");
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/ui.scss";
</style>
