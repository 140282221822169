<template>
  <div
    :class="`screen screen__intro ${
      currentStep === 1 ? 'step-1--active' : 'step-2--active'
    }`"
  >
    <section class="intro__video">
      <video
        v-if="video"
        :src="video[0]"
        :poster="video[1]"
        muted
        autoplay
        loop
        playsinline
      ></video>
    </section>
    <section class="intro__text">
      <div class="logo__wrapper">
        <img src="/assets/logo--big.png" alt="Logo Cat Genius" class="logo" />
      </div>
      <transition-group name="slide">
        <div
          key="step-1"
          class="intro__step intro__step-1"
          v-if="!country && currentStep === 1"
        >
          <SelectInputLanguage
            :label="$trans('introLanguageSelectionLabel')"
            name="language-select"
            :data="options"
            :selection="$currentSite().handle"
            @input="handleSelect"
          />
        </div>
        <div
          key="step-2"
          class="intro__step intro__step-2"
          v-if="currentStep === 2"
        >
          <h3>{{ $trans("introInfoTitle") }}</h3>
          <p v-html="$trans('introInfo')"></p>
          <Button @click.native="handleStartClick()" :game="true">
            {{ $trans("introButton") }}
          </Button>
        </div>
      </transition-group>
      <div class="intro__paws">
        <img src="/assets/img/paw.png" alt="Paw image" />
        <img src="/assets/img/paw.png" alt="Paw image" />
        <img src="/assets/img/paw.png" alt="Paw image" />
      </div>
      <Footer class="intro__footer" />
    </section>
  </div>
</template>

<script>
import Button from "@/components/buttons/Button";
import SelectInputLanguage from "@/components/inputs/SelectInputLanguage";
import Footer from "@/components/Footer";
import { isTouchDevice } from "@/helpers";

export default {
  name: "IntroScreen",
  components: {
    Button,
    SelectInputLanguage,
    Footer,
  },
  data() {
    return {
      options: this.$sitesAvailable().map((s) => {
        return { key: s.handle, label: s.name };
      }),
      country: localStorage.getItem("country")
        ? localStorage.getItem("country")
        : null,
      currentStep: localStorage.getItem("country") ? 2 : 1,
      video: this.$videos[Math.floor(Math.random() * this.$videos.length)],
    };
  },
  methods: {
    isTouchDevice: isTouchDevice,
    handleSelect(location) {
      localStorage.setItem("country", location);
      const isCurrent = this.$currentSite().handle === location;
      const isAvailable = this.$sitesAvailable()
        .map((s) => s.handle)
        .includes(location);
      if (!isCurrent && isAvailable) {
        window.location.href = `/${location}`;
      } else {
        this.currentStep++;
      }
      this.sendGtmEvent("language-select", {
        target: "language-dropdown",
        value: location,
        language: localStorage.getItem("country"),
      });
    },
    handleStartClick() {
      this.sendGtmEvent("button-click", {
        target: "start-button",
        value: this.$trans("introButton"),
        page: "intro_page",
        language: localStorage.getItem("country"),
      });
      this.$emit("start-loading");
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/screens/intro-screen.scss";
</style>
