<template>
  <button
    :class="`icon-button ${background ? 'bg' : ''} ${circle ? 'circle' : ''} ${
      shadow ? 'shadow' : ''
    } ${scale ? 'scale' : ''}`"
    :aria-label="icon"
  >
    <img
      :src="`/assets/icon/icon-${icon}.svg`"
      alt=""
      :class="`icon icon-${color}`"
    />
  </button>
</template>

<script>
export default {
  name: "IconButton",
  props: ["icon", "color", "background", "circle", "shadow", "scale"],
};
</script>

<style lang="scss" scoped>
.icon-button {
  transition: background-color 0.1s linear, box-shadow 0.1s linear;
  border: none;
  background: transparent;
  cursor: pointer;
  min-height: 5rem;
  min-width: 5rem;
  @include scaleWidth(5);
  @include scaleHeight(5);
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    width: 100%;
    height: 100%;
    transition: filter $basic-transition-100, transform $basic-transition-200;

    &-black {
      // #273841
      filter: invert(14%) sepia(12%) saturate(1755%) hue-rotate(157deg)
        brightness(94%) contrast(81%);
    }

    &-white {
      // #fff
      filter: invert(100%) sepia(0%) saturate(6922%) hue-rotate(23deg)
        brightness(111%) contrast(105%);
    }

    &-green {
      // #aec999
      filter: invert(75%) sepia(20%) saturate(397%) hue-rotate(51deg)
        brightness(100%) contrast(90%);
    }
  }

  &.scale:hover {
    img {
      transform: scale(1.5);
    }
  }

  &.bg {
    background: $color-white;

    &:hover {
      background: darken($color-white, 10%);
      cursor: pointer;
    }
  }

  &.circle {
    border-radius: 5rem;
  }

  &.shadow {
    box-shadow: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.25);

    &:hover {
      box-shadow: 0 0.4rem 1.2rem rgba(0, 0, 0, 0.25);
    }
  }

  @include for-giant-desktop-up {
    height: rs(50);
    width: rs(50);

    &.circle {
      border-radius: rs(50);
    }
  }
}
</style>
