import { Howl } from "howler";

const UiState = {
  INIT: 0,
  LOADING: 1,
  QUIZ: 2,
  PLAYING: 3,
  FINISHED: 4,
};

const GameState = {
  INTRO: 0,
  WALKING: 1,
  FISHING_INTRO: 2,
  FISHING: 3,
  FISHING_END: 4,
  FINISHED_INTRO: 5,
  FINISHED: 6,
  PAUSED: 7,
};

let Sounds = [
  // Ambient
  {
    key: "kojiIsland",
    initVolume: 0.2,
    src: new Howl({
      src: ["/assets/sounds/ambient.mp3"],
      loop: true,
      volume: 0.2,
      preload: false,
    }),
  },
  // Quiz
  {
    key: "openQuizSound",
    initVolume: 1,
    src: new Howl({
      src: ["/assets/sounds/quiz/openQuiz.mp3"],
      preload: false,
    }),
  },
  {
    key: "correctAnswerSound",
    initVolume: 1,
    src: new Howl({
      src: ["/assets/sounds/cat/165887188-cat-purring-and-eating-01.mp3"],
      preload: false,
      sprite: {
        purr: [0, 2000],
      },
    }),
  },
  // Whack-a-Koi
  {
    key: "hitKoiSound",
    initVolume: 0.6,
    src: new Howl({
      src: ["/assets/sounds/koi/koiHit.mp3"],
      preload: false,
      volume: 0.6,
    }),
  },
  {
    key: "clockTickSound",
    initVolume: 0.5,
    src: new Howl({
      src: ["/assets/sounds/koi/clockTick.mp3"],
      loop: true,
      rate: 0.7,
      volume: 0.5,
      preload: false,
    }),
  },
  // Cat
  {
    key: "meow1",
    initVolume: 1,
    src: new Howl({
      src: ["/assets/sounds/cat/meow1.mp3"],
      preload: false,
    }),
  },
  {
    key: "meow2",
    initVolume: 1,
    src: new Howl({
      src: ["/assets/sounds/cat/meow2.mp3"],
      preload: false,
    }),
  },
  {
    key: "meow3",
    initVolume: 1,
    src: new Howl({
      src: ["/assets/sounds/cat/meow3.mp3"],
      preload: false,
    }),
  },
  {
    key: "meow4",
    initVolume: 1,
    src: new Howl({
      src: ["/assets/sounds/cat/meow4.mp3"],
      preload: false,
    }),
  },
  {
    key: "meow5",
    initVolume: 1,
    src: new Howl({
      src: ["/assets/sounds/cat/meow5.mp3"],
      preload: false,
    }),
  },
  // Game
  {
    key: "water1",
    initVolume: 1,
    src: new Howl({
      src: ["/assets/sounds/koi/splash1.mp3"],
      preload: false,
    }),
  },
  {
    key: "water2",
    initVolume: 1,
    src: new Howl({
      src: ["/assets/sounds/koi/splash2.mp3"],
      preload: false,
    }),
  },
  {
    key: "catEating",
    initVolume: 1,
    src: new Howl({
      src: ["/assets/sounds/cat/165887188-cat-purring-and-eating-01.mp3"],
      preload: false,
    }),
  },
  {
    key: "cars",
    initVolume: 1,
    src: new Howl({
      src: ["/assets/sounds/018189854-cars-passing-lonely-street.mp3"],
      preload: false,
    }),
  },
  {
    key: "walkUpstairs",
    initVolume: 1,
    src: new Howl({
      src: ["/assets/sounds/069448757-walking-upstairs-house-carpet-.mp3"],
      preload: false,
    }),
  },
  {
    key: "basementAmbient",
    initVolume: 0,
    src: new Howl({
      src: ["/assets/sounds/123790175-roomtone-basement-hum-clicks-a.mp3"],
      preload: false,
    }),
  },
  {
    key: "rockyMountain",
    initVolume: 1,
    src: new Howl({
      src: ["/assets/sounds/114833211-stream-loop-rocky-mountian-lar.mp3"],
      preload: false,
    }),
  },
  {
    key: "birdswallow",
    initVolume: 1,
    src: new Howl({
      src: ["/assets/sounds/008718196-birdswallow.mp3"],
      preload: false,
    }),
  },
  {
    key: "cityAtmosphere",
    initVolume: 1,
    src: new Howl({
      src: ["/assets/sounds/031523950-a4n-092-city-garden-atmosphere.mp3"],
      preload: false,
    }),
  },
];

export { UiState, GameState, Sounds };
