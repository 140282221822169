<template>
  <div class="select-input-language">
    <label>
      <h2>{{ label }}</h2>
    </label>
    <v-select
      :options="sortedData"
      v-model="selected"
      class="select-wrapper"
      :reduce="(x) => x.key"
      :searchable="false"
      :clearable="false"
      @input="$emit('input', selected)"
      :placeholder="label"
      :dropdown-should-open="dropdownShouldOpen"
      @click.native="clickSelect"
    >
      <template #open-indicator="{ attributes }">
        <img v-bind="attributes" :src="`/assets/icon/icon-caret--down.svg`" />
      </template>
      <template #option="option">
        <img :src="`/assets/icon/icon-flag--${option.key}.svg`" class="flag" />
        <span>{{ option.label }}</span>
      </template>
      <template #selected-option="option">
        <img :src="`/assets/icon/icon-flag--${option.key}.svg`" class="flag" />
        <span>{{ option.label }}</span>
      </template>
    </v-select>
    <div v-if="modal" class="select-modal" @click.prevent="modal = false">
      <ul>
        <li
          v-for="option in data"
          :key="option.key"
          @click.prevent="
            selected = option.key;
            $emit('input', selected);
          "
        >
          <img :src="`/assets/icon/icon-flag--${option.key}.svg`" class="flag" />
          <span>{{ option.label }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { isTouchDevice } from "@/helpers";

export default {
  name: "SelectInputLanguage",
  props: ["label", "data", "selection", "left-icon"],
  components: { vSelect },
  data: () => ({ selected: null, modal: false }),
  computed: {
    sortedData() {
      let sorted = [...this.data];
      sorted.push(sorted.shift());
      return sorted;
    },
  },
  methods: {
    dropdownShouldOpen(VueSelect) {
      if (isTouchDevice()) {
        return false;
      }
      return VueSelect.open;
    },
    clickSelect() {
      if (isTouchDevice()) {
        this.modal = true;
      }
    },
  },
};
</script>

<style lang="scss">
.select-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 15;

  ul {
    text-align: left;
    background: $color-white;
    border-radius: 0.8rem;
    box-shadow: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.25);
    min-height: 5rem;
    padding: 0.4rem 0;
    margin: 0;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    height: auto;
    width: 90%;

    * {
      color: $color-text;
      font-weight: 600;
      text-transform: uppercase;
    }

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 1rem 2rem;

      img.flag {
        max-width: 2rem;
        @include scaleWidth(2);
        margin-right: 1.6rem;
      }

      &:hover,
      &:active {
        background-color: $color-blue--secondary;

        span {
          color: $color-white;
        }
      }
    }
  }
}

.select-input-language {
  --vs-actions-padding: 0 0.5rem 0 0;
  width: 100%;

  @include for-tablet-portrait-up {
    width: auto;

    .vs__dropdown-toggle {
      min-width: 40rem !important;
      // @include scaleWidth(40);
    }
  }

  label h2 {
    // color: $color-text--inverse;
    margin: 2rem;
  }

  .select-wrapper {
    * {
      color: $color-text;
      font-weight: 600;
      text-transform: uppercase;
    }

    .vs__dropdown-toggle {
      min-height: 5rem;
      // @include scaleHeight(5);
      min-width: unset;

      background: $color-white;
      border-radius: 0.8rem;
      border: none;
      box-shadow: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.25);
      padding: 0;

      transition: all 0.1s linear;

      &:hover {
        box-shadow: 0 0.4rem 1.2rem rgba(0, 0, 0, 0.25);
      }
    }

    .vs__search {
      padding: 0 0 0 2rem;

      &::placeholder {
        @include fontSize(1.4rem, 1.6, 1.6rem);
      }
    }

    .vs__dropdown-option {
      padding: 1rem 2rem;
      display: flex;
      align-items: center;
    }

    .vs__dropdown-menu {
      margin-top: 1rem;
      border-radius: 0.8rem;
    }

    .vs__open-indicator {
      // #C0C0C0
      filter: invert(76%) sepia(53%) saturate(0%) hue-rotate(172deg)
        brightness(93%) contrast(90%);
    }

    .vs__selected {
      height: 100%;
      width: 100%;
      padding: 0 0 0 2rem;
      margin: 0;
      display: flex;
      align-items: center;
    }

    img.flag {
      max-width: 2rem;
      @include scaleWidth(2);
      margin-right: 1.6rem;
    }
  }

  @include for-giant-desktop-up {
    .vs__dropdown-toggle {
      min-width: rs(400) !important;
    }

    label h2 {
      margin: rs(20);
    }

    .select-wrapper {
      * {
        font-size: rs(16) !important;
      }

      .vs__open-indicator {
        width: rs(30);
      }

      .vs__dropdown-toggle {
        min-height: rs(50);
        border-radius: rs(8);
      }

      .vs__search {
        padding: 0 0 0 rs(20);

        &::placeholder {
          font-size: rs(16) !important;
        }
      }

      .vs__dropdown-option {
        padding: rs(10) rs(20);
      }

      .vs__dropdown-menu {
        margin-top: rs(10);
        border-radius: rs(8);
        max-height: unset;
      }

      .vs__selected {
        padding: 0 0 0 rs(20);
      }

      img.flag {
        max-width: rs(20);
        margin-right: rs(16);
      }
    }
  }
}
</style>
