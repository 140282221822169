<template>
  <div :class="`screen screen__quiz screen__quiz--${theme}`" id="screen">
    <!-- TODO: Temporary skip button -->
    <button
      v-if="!production"
      class="temp-button"
      @click="handleContinueClick()"
    >
      Finish question
    </button>

    <!-- Reveal right/wrong animation -->
    <div
      v-if="selectedAnswerCorrect && answerAnimating"
      class="question__animation"
    >
      <lottie
        v-if="answerAnimating"
        class="anim anim__check"
        :options="answerCorrectOptions"
        v-on:animCreated="handleAnimation($event, answerAnimationSpeed)"
      />
    </div>
    <div
      v-if="!selectedAnswerCorrect && answerAnimating"
      class="question__animation"
    >
      <lottie
        v-if="answerAnimating"
        class="anim anim__cross"
        :options="answerWrongOptions"
        v-on:animCreated="handleAnimation($event, answerAnimationSpeed)"
      />
    </div>

    <!-- Question face -->
    <section class="question__container" v-if="question">
      <div
        :class="`question__grid__overlay question__grid__overlay--top question__grid__overlay--top-${theme}`"
      ></div>

      <NumberProgress :active="index" />

      <div class="question__wrapper">
        <h2 class="question__title">{{ question.title }}</h2>
        <div v-if="question.questionCat.length > 0" class="cat__wrapper">
          <img
            :class="`cat ${question.questionCat}`"
            :src="question.questionCat"
            alt="Graphic of cat in regards to question"
          />
          <!-- <div :class="`cat__stand cat__stand--${theme}`">
            <div class="cat__stand--front-face"></div>
            <div class="cat__stand--top-face"></div>
          </div> -->
        </div>
        <div
          :class="`question__grid ${
            question.questionCat.length > 0 ? 'has-cat' : ''
          } ${question.questionsAnswers.length > 2 ? 'four' : 'two'}`"
        >
          <div
            v-for="(answerObj, index) in question.questionsAnswers"
            :key="index"
            :class="`question__answer ${theme} ${
              answerObj.correctAnswer
                ? 'question__answer--correct'
                : 'question__answer--wrong'
            }`"
            @click="handleAnswerClick(answerObj, index)"
            :id="index"
          >
            <p>{{ answerObj.answer }}</p>
          </div>
        </div>

        <transition name="slide-up">
          <div class="question__container__actions" v-if="currentStep >= 1">
            <!-- <div class="pagination">
              <div
                :class="`dot ${currentStep === 1 ? 'dot--active' : ''}`"
              ></div>
              <div
                :class="`dot ${currentStep === 2 ? 'dot--active' : ''}`"
              ></div>
            </div> -->
            <Button
              @click.native="handleMobileContinueClick()"
              class="button--white button--shadow"
              :arrow="true"
              :arrowColor="theme"
            >
              {{ $trans("gameContinueButton") }}
            </Button>
            <div class="avatar__wrapper" @click="handleMobileContinueClick()">
              <img src="/assets/img/veterinary--avatar.png" alt="Vet avatar" />
            </div>
          </div>
        </transition>
      </div>

      <!-- <div
        :class="`question__grid__overlay question__grid__overlay--bottom question__grid__overlay--bottom-${theme}`"
      ></div> -->
    </section>

    <!-- Vet info face -->
    <section :class="`question__info ${theme}`" v-if="question">
      <transition-group name="slide">
        <div
          class="question__info__content"
          v-if="!animating && vetInfo"
          key="info"
        >
          <div class="avatar__wrapper">
            <img src="/assets/img/veterinary--avatar.png" alt="Vet avatar" />
          </div>
          <div class="question__info__content--inner">
            <h2>
              {{
                selectedAnswerCorrect
                  ? $trans("gameCorrectAnswer")
                  : $trans("gameWrongAnswer")
              }}
            </h2>
            <p class="text" v-html="question.questionsInfo"></p>
          </div>
        </div>
        <div
          :class="`question__info__actions question__info__actions-${theme}`"
          v-if="!animating && vetInfo"
          key="actions"
        >
          <!-- <div class="pagination" v-if="currentStep === 2">
            <div :class="`dot ${currentStep === 1 ? 'dot--active' : ''}`"></div>
            <div :class="`dot ${currentStep === 2 ? 'dot--active' : ''}`"></div>
          </div> -->
          <Button
            @click.native="handleContinueClick()"
            class="button--white button--shadow button--margin"
            :arrow="true"
            :arrowColor="theme"
          >
            {{ $trans("gameContinueButton") }}
          </Button>
        </div>
      </transition-group>
    </section>
  </div>
</template>

<script>
import * as SoundController from "@/helpers/sounds";
import { isTouchDevice } from "@/helpers";
import NumberProgress from "@/components/questions/NumberProgress";
import Button from "@/components/buttons/Button";
import Lottie from "vue-lottie";
import * as answerCorrectData from "../../web/assets/lottie/answer--correct.json";
import * as answerWrongData from "../../web/assets/lottie/answer--wrong.json";

export default {
  name: "QuizScreen",
  props: ["index"],
  components: {
    NumberProgress,
    Button,
    lottie: Lottie,
  },
  data() {
    return {
      question: this.$primaryQuestionSet().concat(this.$secondaryQuestionSet())[
        this.index - 1
      ],
      animating: false,
      answerAnimating: false,
      vetInfo: false,
      selectedAnswerIndex: null,
      selectedAnswerCorrect: false,
      answerCorrectOptions: { animationData: answerCorrectData.default },
      answerWrongOptions: { animationData: answerWrongData.default },
      answerAnimationSpeed: 1,
      currentStep: 0,
      production: process.env.NODE_ENV === "production",
    };
  },
  computed: {
    theme() {
      let green = [1, 2, 3, 4, 5, 8];
      let orange = [6, 7];
      let purple = [9, 10];
      if (green.includes(this.index)) return "green"; // [1,4] - Green
      if (orange.includes(this.index)) return "orange"; // [5,7] - Orange
      if (purple.includes(this.index)) return "purple"; // [8,10] - Purple
      return "green"; // ][ - Green
    },
  },
  methods: {
    handleAnswerClick(answerObj, index) {
      let answerCards = document.querySelectorAll(".question__answer");
      answerCards.forEach((card) => {
        card.style.pointerEvents = "none";
      });

      // Define answer is correct or not
      const correctAnswer = answerObj.correctAnswer === "1";
      if (!this.selectedAnswerIndex) {
        this.selectedAnswerCorrect = correctAnswer;
        this.$store.state.questionAnswers.push({
          question: this.question,
          selected: answerObj,
          correct: correctAnswer,
        });
        if (correctAnswer) {
          this.handleCorrectClick(index);
        } else {
          this.handleWrongClick(index);
        }
      }

      // After click animations
      this.endAnimation();
    },
    handleCorrectClick(index) {
      SoundController.play("correctAnswerSound", "purr");
      this.selectedAnswerIndex = index;
      let corrects = document.querySelectorAll(".question__answer--correct");
      corrects.forEach((corr) => {
        corr.classList.add("question__answer--correct-selected");
      });

      let wrongs = document.querySelectorAll(".question__answer--wrong");
      wrongs.forEach((wrong) => {
        wrong.classList.add("question__answer--wrong-noselected");
      });
    },
    handleWrongClick(index) {
      SoundController.playRandomMeow();
      this.selectedAnswerIndex = index;
      let corrects = document.querySelectorAll(".question__answer--correct");
      corrects.forEach((corr) => {
        corr.classList.add("question__answer--correct-noselected");
      });

      let wrongs = document.querySelectorAll(".question__answer--wrong");
      wrongs.forEach((wrong) => {
        if (wrong.id != index) {
          wrong.classList.add("question__answer--wrong-noselected");
        }
      });

      let wrongSelected = document.getElementById(index);
      wrongSelected.classList.add("question__answer--wrong-selected");
    },
    endAnimation() {
      setTimeout(() => {
        // Display full size animation
        this.answerAnimating = true;

        setTimeout(() => {
          this.answerAnimating = false;

          // Wrong: wiggle correct
          if (!this.selectedAnswerCorrect) {
            let correctAnswer = document.querySelectorAll(
              ".question__answer--correct-noselected"
            )[0];
            if (isTouchDevice()) {
              correctAnswer.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
              });
            }
            correctAnswer.classList.add("wiggle");
            setTimeout(() => {
              correctAnswer.classList.remove("wiggle");

              // Display vet info
              if (isTouchDevice()) {
                document
                  .getElementById("screen")
                  .classList.add("info--available");
                this.currentStep++;
              } else {
                this.showInfo();
              }
            }, 1000);
          } else {
            // Display vet info
            if (isTouchDevice()) {
              document
                .getElementById("screen")
                .classList.add("info--available");
              this.currentStep++;
            } else {
              this.showInfo();
            }
          }
        }, 1500);
      }, 200);
    },
    showInfo() {
      document.getElementById("screen").classList.add("info--active");
      this.vetInfo = true;
    },
    handleMobileContinueClick() {
      this.currentStep++;
      this.showInfo();
    },
    handleContinueClick() {
      this.sendGtmEvent("finish-question", {
        target: "continue-button",
        value: this.$trans("cookieButton"),
        question_title: this.question.title,
        question_index: this.index,
        question_correct: this.selectedAnswerCorrect,
        language: localStorage.getItem("country")
          ? localStorage.getItem("country")
          : "not_selected",
      });
      this.$emit("finish-quiz", this.index);
      this.$store.state.currentState = this.$uiState.PLAYING;
      this.$store.state.currentGameState = this.$gameState.WALKING;
    },
    handleAnimation(anim, speed) {
      this.anim = anim;
      this.anim.setSpeed(speed);
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/screens/quiz-screen.scss";
</style>
