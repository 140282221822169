import * as THREE from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';


class Cat {


    constructor() {
        this.mesh = null;
        this.mixer = null;
        this.animations = null;
        //this.gui = gui;
        this.runClip = null;

        this.actionIdle = null;
        this.actionIdleSit = null;
        this.actionWalk = null;
        this.actionRunning = null;
        this.actionEating = null;

    }


    setIdleSit() {
        if (this.actionIdleSit) this.actionIdleSit.play();
        if (this.actionRunning) this.actionRunning.stop();
        if (this.actionIdle) this.actionIdle.stop();
    }


    setEating() {

        if (this.actionRunning) this.actionRunning.stop();
        if (this.actionIdle) this.actionIdle.stop();
        if (this.actionIdleSit) this.actionIdleSit.stop();

        if (this.actionEating) {
            console.log("EAT");
            this.actionEating.play();
        }

    }


    setIdle() {
        this.actionIdle.reset();
        this.actionIdle.play();
        this.actionWalk.crossFadeTo(this.actionIdle, 1);
    }

    setRunning() {
        if (this.actionEating) this.actionEating.stop();
        if (this.actionIdleSit) this.actionIdleSit.stop();

        if (this.actionIdle) this.actionIdle.play();
        if (this.actionRunning) {
            this.actionRunning.play();
        }
    }

    setWalkWeight(weight) {
        weight = Math.abs(weight);
        this.actionRunning.setEffectiveWeight(weight);
        this.actionIdle.setEffectiveWeight(1 - weight);
    }


    hide() {
        if (this.mesh) this.mesh.visible = false;
    }

    show() {
        if (this.mesh) this.mesh.visible = true;
    }

    load(callback) {

        const loader = new GLTFLoader();

        const _this = this;
        loader.load('/models/Cat.gltf', function (gltfData) {

            const mesh = gltfData.scene;

            const catColor = new THREE.Color( 0xae5c22 );

            mesh.traverse(function (o) {
                if(o.material){
                    o.material.color = catColor;
                    o.material.needsUpdate = true;
                }
            });


            _this.mesh = mesh;
            _this.animations = gltfData.animations;


            //console.log("==== CAT glb ====");
            //console.log("cat animations " + gltfData.animations.length);

            // animations
            _this.mixer = new THREE.AnimationMixer(mesh);

            // actions
            /*
            Eating
            Idle
            Jumping Down
            Jumping Up
            Running
            Sitting Idle
            Walking
            */
            _this.actionIdle = _this.mixer.clipAction(THREE.AnimationClip.findByName(_this.animations, "Idle"));
            _this.actionIdleSit = _this.mixer.clipAction(THREE.AnimationClip.findByName(_this.animations, "Sitting Idle"));
            _this.actionRunning = _this.mixer.clipAction(THREE.AnimationClip.findByName(_this.animations, "Walking"));
         //   _this.actionRunning = _this.mixer.clipAction(THREE.AnimationClip.findByName(_this.animations, "Running"));
            _this.actionEating = _this.mixer.clipAction(THREE.AnimationClip.findByName(_this.animations, "Eating"));

            _this.setIdleSit();
            callback(mesh);

        }, undefined, function (e) {
            console.error(e);

        });

    }


    update(div) {
        if (this.mixer) 
            {
                
                this.mixer.update(div);        
                

            }
    }

}

export { Cat };