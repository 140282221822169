var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"ui"},[_c('h1',{staticClass:"hidden"},[_vm._v("Cat Genius")]),_c('transition',{attrs:{"name":"fade"}},[(_vm.state === _vm.$uiState.PLAYING || _vm.isLandscape || _vm.isWrongResolution)?_c('a',{key:"white",class:("logo__wrapper--home ui__element ui__element--top-left " + (_vm.state !== _vm.$uiState.INIT && _vm.state !== _vm.$uiState.FINISHED
          ? 'in-game'
          : '')),attrs:{"href":"/"}},[_c('img',{staticClass:"logo--ol",attrs:{"src":"/assets/logo--ol-white.png","alt":"Cat Genius logo"}})]):_c('a',{key:"black",class:("logo__wrapper--home ui__element ui__element--top-left " + (_vm.state !== _vm.$uiState.INIT && _vm.state !== _vm.$uiState.FINISHED
          ? 'in-game'
          : '')),attrs:{"href":"/"}},[_c('img',{staticClass:"logo--ol",attrs:{"src":"/assets/logo--ol-black.png","alt":"Cat Genius logo"}})])]),_c('div',{staticClass:"ui__actions ui__element ui__element--top-right"},[_c('IconButton',{attrs:{"scale":true,"icon":_vm.soundOn ? 'sound--on' : 'sound--off',"color":_vm.$store.state.currentState === _vm.$uiState.PLAYING ||
        _vm.isLandscape ||
        _vm.isWrongResolution
          ? 'white'
          : 'black'},nativeOn:{"click":function($event){return _vm.toggleSound.apply(null, arguments)}}})],1),(_vm.isLandscape || _vm.isWrongResolution)?_c('DimensionsScreen',{key:"intro",attrs:{"isWrongResolution":_vm.isWrongResolution,"isLandscape":_vm.isLandscape}}):_vm._e(),_c('transition-group',{attrs:{"name":"slide-down"}},[(_vm.state === _vm.$uiState.PLAYING && _vm.gameState === _vm.$gameState.FISHING)?_c('div',{key:"timer",staticClass:"ui__koi ui__koi__timer ui__element ui__element--top-center timer__wrapper"},[_c('p',{attrs:{"id":"timer"}},[_vm._v(" "+_vm._s(_vm.timer)+" ")]),_c('Button',{staticClass:"button--outline skip button--small",nativeOn:{"click":function($event){return _vm.handleSkipClick()}}},[_vm._v(" "+_vm._s(_vm.$trans("koiSkip"))+" ")])],1):_vm._e(),(
        _vm.state === _vm.$uiState.PLAYING &&
        _vm.gameState !== _vm.$gameState.FISHING_INTRO &&
        _vm.gameState !== _vm.$gameState.FISHING &&
        _vm.gameState !== _vm.$gameState.FISHING_END &&
        _vm.$store.state.questionAnswers.length > 0
      )?_c('BarProgress',{key:"progress",staticClass:"ui__element ui__element--top-center"}):_vm._e(),(
        (_vm.state === _vm.$uiState.PLAYING && _vm.gameState === _vm.$gameState.FISHING) ||
        (_vm.state === _vm.$uiState.PLAYING &&
          _vm.gameState !== _vm.$gameState.FISHING_INTRO &&
          _vm.gameState !== _vm.$gameState.FISHING &&
          _vm.gameState !== _vm.$gameState.FISHING_END &&
          _vm.$store.state.questionAnswers.length > 0)
      )?_c('div',{key:"overlay",staticClass:"ui__koi__overlay"}):_vm._e()],1),_c('transition',{attrs:{"name":"slide-bottom"}},[(_vm.state === _vm.$uiState.PLAYING && _vm.gameState === _vm.$gameState.FISHING)?_c('div',{staticClass:"ui__koi ui__koi__score ui__element ui__element--koi-score"},[_c('p',[_vm._v(_vm._s(_vm.currentKoiScore))]),_c('div',{staticClass:"img__wrapper"},[_c('img',{attrs:{"src":"/assets/img/koi--score.png","alt":"Koi fish indication","id":"fish"}})])]):_vm._e()]),(_vm.state === _vm.$uiState.PLAYING && _vm.gameState === _vm.$gameState.FISHING)?_c('span',{staticClass:"ui__element ui__element--center",attrs:{"id":"hit"}},[_vm._v(" +1 ")]):_vm._e(),_c('transition-group',{attrs:{"name":"fade"}},[(_vm.state === _vm.$uiState.PLAYING && _vm.gameState === _vm.$gameState.INTRO)?_c('IntroPrompt',{key:"intro",on:{"allow-interaction":function($event){return _vm.$emit('allow-interaction')}}}):_vm._e(),(
        _vm.state === _vm.$uiState.PLAYING && _vm.gameState === _vm.$gameState.FISHING_INTRO
      )?_c('KoiPrompt',{key:"koi",on:{"count-down":function($event){_vm.showOverlay = false},"start-whackakoi":function($event){_vm.startTimer();
        _vm.$emit('start-whackakoi');
        _vm.showOverlay = true;},"skip-whackakoi":function($event){return _vm.$emit('skip-whackakoi')}}}):_vm._e(),(
        _vm.state === _vm.$uiState.PLAYING && _vm.gameState === _vm.$gameState.FISHING_END
      )?_c('TimesUpPrompt',{key:"timesup",on:{"finish-whackakoi":function($event){return _vm.$emit('finish-whackakoi')}}}):_vm._e(),(
        (_vm.state === _vm.$uiState.PLAYING &&
          _vm.gameState === _vm.$gameState.FISHING_END) ||
        (_vm.state === _vm.$uiState.PLAYING &&
          _vm.gameState === _vm.$gameState.FISHING_INTRO &&
          _vm.showOverlay)
      )?_c('div',{key:"overlay",staticClass:"ui__koi ui__koi__overlay--multiply-blue"}):_vm._e(),(
        _vm.state === _vm.$uiState.PLAYING && _vm.gameState === _vm.$gameState.FINISHED_INTRO
      )?_c('EndPrompt',{key:"end",on:{"finish-game":function($event){return _vm.finishGame()}}}):_vm._e()],1),(!_vm.isLandscape)?_c('transition-group',{attrs:{"name":"fade"}},[(_vm.state === _vm.$uiState.INIT && _vm.gameState === _vm.$gameState.PAUSED)?_c('IntroScreen',{key:"intro",on:{"start-loading":function($event){return _vm.startLoading()}}}):_vm._e(),(_vm.state === _vm.$uiState.LOADING && _vm.gameState === _vm.$gameState.PAUSED)?_c('LoadingScreen',{key:"loading",attrs:{"progress":_vm.currentLoadPrecentage},on:{"finish-loading":function($event){return _vm.finishLoading()}}}):_vm._e(),(
        _vm.state === _vm.$uiState.QUIZ &&
        _vm.currentQuestion &&
        _vm.gameState === _vm.$gameState.PAUSED
      )?_c('QuizScreen',{key:"quiz",attrs:{"index":_vm.currentQuestion},on:{"finish-quiz":function($event){return _vm.$emit('finish-quiz', $event)}}}):_vm._e(),(_vm.state === _vm.$uiState.FINISHED && _vm.gameState === _vm.$gameState.FINISHED)?_c('FinishedScreen',{key:"finished"}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }